class ZIDXWixSettings implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxWixSettingsContainer";
    }
    getMatchURL(){
        return "/wix-settings/";
    }
    render(){
        // console.log("wix settings");

    }
}